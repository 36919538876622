import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import Icon from '../../Icon';

const ScrollButton = () => (
	<Link
		to="header"
		href="/"
		spy={true}
		smooth={true}
		duration={600}
		exact="true"
		offset={-200}
		id="backToTopBtn"
	>
		<Span className="fixed z-10 grid text-2xl transition-all rounded-full shadow-lg opacity-75 cursor-pointer bottom-14 right-10 hover:transform transform-gpu w-14 h-14 place-items-center hover:scale-110 ">
			<Icon type="fas" icon="long-arrow-alt-up" color="white" />
		</Span>
	</Link>
);

export default ScrollButton;

const Span = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--primary);
	svg {
		width: 50px;
	}
`;
